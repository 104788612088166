<template>
  <!--  <b-overlay show spinner-variant="success" spinner-type="grow" spinner-small rounded="sm">-->
  <section class="auth">
    <div class="auth-container">
      <b-form @submit.prevent="handleSubmit" class="auth-form">
        <b-overlay :show="isAuthLoading" spinner-variant="success" rounded="sm" opacity="0.3">
          <div class="text-center mb-2">
            <img
              @click="$router.push('/')"
              class="logo cursor-pointer mb-3"
              src="@/assets/images/flexlogo.png"
              alt="Logo"
            />
          </div>
          <h4 class="auth__title">Сброс пароля</h4>
          <b-form-group id="input-group-1" label="Номер телефона:" label-for="input-1">
            <b-form-input
              v-model.trim="phone"
              id="input-1"
              type="text"
              placeholder="+7 ("
              v-mask="'+7 (###) ### ## ##'"
              required
              :state="validatePhone"
              :disabled="isResetPasswordRequested"
            ></b-form-input>
            <b-form-invalid-feedback :state="validatePhone">
              Введите корректный номер телефона
            </b-form-invalid-feedback>
          </b-form-group>
          <template v-if="isResetPasswordRequested">
            <b-form-group id="input-group-2" label="Смс-код:" label-for="input-2">
              <b-form-input
                v-model="code"
                id="input-2"
                type="number"
                placeholder="Введите смс-код"
                required
                :state="validateCode"
              ></b-form-input>
              <b-form-invalid-feedback :state="validateCode"> Введите корректный смс-код </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-3" label="Пароль:" label-for="input-3">
              <b-form-input
                v-model="password"
                id="input-3"
                type="password"
                placeholder="Введите новый пароль"
                required
                :state="validatePassword"
              ></b-form-input>
              <b-form-invalid-feedback :state="validatePassword">
                Пароль должен содержать минимум 4 символа
              </b-form-invalid-feedback>
            </b-form-group>
          </template>
          <div class="mt-4 text-center">
            <b-button type="submit" variant="success">
              {{ isResetPasswordRequested ? 'Поменять пароль' : 'Запросить смс-код' }}
            </b-button>
          </div>

          <div class="mt-3 text-center font-weight-bold">
            Не зарегистрированы? <br />
            <router-link :to="{ name: 'auth.registration' }" class="text-success">Зарегистрироваться </router-link>
          </div>

          <div class="mt-3 text-center font-weight-bold cursor-pointer text-success">
            <router-link :to="{ name: 'layout.main' }" class="text-success">Продолжить без авторизации</router-link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="ml-2"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </div>
        </b-overlay>
      </b-form>
    </div>
  </section>
</template>

<script>
import { mask } from 'vue-the-mask'
import Api from '@/services/api'

export default {
  name: 'ForgotPasswordPage',
  directives: { mask },
  data: () => ({
    isAuthLoading: false,
    isResetPasswordRequested: false,
    phone: '',
    password: '',
    code: '',
    isTouched: false,
  }),

  computed: {
    validatePhone() {
      if (!this.isTouched) return null
      return this.phone.length === 18
    },

    validatePassword() {
      if (!this.isTouched) return null
      return this.password.length >= 4
    },

    validateCode() {
      if (!this.isTouched) return null
      return this.code.length >= 4
    },
  },

  methods: {
    async handleSubmit() {
      this.isTouched = true
      if (!this.isResetPasswordRequested) {
        if (!this.validatePhone) return
        try {
          this.isAuthLoading = true
          await this.wrapAndHandleRequest(() =>
            Api.resetPasswordRequest({
              username: this.phone.replace(/[+() ]/g, ''),
              method: 2,
            }),
          )
          this.isTouched = false
          this.isResetPasswordRequested = true
        } finally {
          this.isAuthLoading = false
        }
      } else {
        if (!this.validateData()) return
        try {
          this.isAuthLoading = true
          await this.wrapAndHandleRequest(() =>
            Api.resetPasswordBySms({
              password: this.password,
              activationCode: this.code,
              phone: this.phone.replace(/[+() ]/g, ''),
            }),
          )
          await this.$router.push({ name: 'auth.login' })
          this.makeToast('Сброс пароля', 'Вы успешно сменили пароль ', 'success', false)
        } finally {
          this.isAuthLoading = false
        }
      }
    },

    validateData() {
      return this.validatePhone && this.validatePassword && this.validateCode
    },
  },
}
</script>
